<template>
  <textarea
    name="content"
    v-model="data.value"
    :placeholder="$t('feedback.comments')"
  ></textarea>
</template>

<script>
export default {
  name: "Text",
  data: () => {
    return {
      data: {
        value: ""
      }
    };
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    other: {
      type: Boolean,
      default: () => false
    },
    questionId: {
      type: Number,
      default: () => 0
    }
  },
  methods: {
    getAnswer() {
      return {
        questionnaireId:+localStorage.getItem("questionareId"),
        questionId: this.questionId,
        answerText: this.data.value,
        optionId: null
      };
    }
  }
};
</script>

<style scoped></style>
